import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, Typography } from "@mui/material";
import {
  CommentsEditorIcon,
  ExportCodeEditorIcon,
  menuEditorIcon,
} from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setCommentsModel,
  setEmailCredintials,
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
  setOpenVerifyEmail,
  setShowUpgradeModal,
} from "@/redux/slices/toolsSlice";
import {
  setGuestAuthModel,
  setProductionModel,
} from "@/redux/slices/userSlice";
import Collaborators from "@/layout/navbar/components/collaborators";
import JSZip from "jszip";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { ImageMenuComp } from "../image";

interface StyleItem {
  imgSrc: string;
  title: string;
  eventsFuns?: () => void;
}

const EditorOtherMenuButton = () => {
  const { id } = useParams();
  const { filesSrc } = useAppSelector((state) => state.prompt);
  const ProfileData = useAppSelector((state) => state.user.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    const zip = new JSZip();

    // Add each file to the zip
    Object.keys(filesSrc).forEach((fileName) => {
      zip.file(fileName, filesSrc[fileName]);
    });

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `project_${id}.zip`);
    });
  };

  // Define the data outside the component or use useMemo if it depends on props
  const mapDataStyle: StyleItem[] = [
    {
      imgSrc: ExportCodeEditorIcon,
      title: "Export Code",
      eventsFuns: () => {
        if (
          ProfileData?.isEmailVerified === false &&
          ProfileData?.type !== "guest"
        ) {
          dispatch(setOpenVerifyEmail(true));
        } else if (ProfileData?.type === "guest") {
          dispatch(setGuestAuthModel(true));
        } else if (
          ProfileData?.plan?.features?.code_download === false) {
          dispatch(setShowUpgradeModal(true));
        } else {
          handleSave();
        }
      },
    },
    {
      imgSrc: CommentsEditorIcon,
      title: "Comments",
      eventsFuns: () => {
        dispatch(setOpenGlobalFontFamily(false));
        dispatch(setProductionModel(false));
        dispatch(setOpenGlobalPallets(false));
        dispatch(setOpenGlobalTheme(false));
        dispatch(setEmailCredintials(false));
        dispatch(setCommentsModel(true));
      },
    },
  ];

  const handleMenuItemClick = (item: StyleItem) => {
    if (item.eventsFuns) {
      item.eventsFuns();
    }
    handleClose();
  };

  return (
    <Box sx={{ ml: "12px" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          height: "32px",
          minWidth: "40px",
          bgcolor: "transport",
          borderRadius: "6px",
          textTransform: "none",
          color: "text.primary",
          "&:hover": {
            bgcolor: "#E5E7EB",
          },
        }}
      >
        <img src={menuEditorIcon} alt="menu" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem>
          <Collaborators />
        </MenuItem>
        {mapDataStyle.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item)}
            sx={{ minWidth: "160px" }}
          >
            <ImageMenuComp imgSrc={item.imgSrc} title={`image-${index}`} />
            <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default EditorOtherMenuButton;
